.asset-menu-items {
  position: absolute;
  border: 1px solid #32344c;
  border-radius: 4px;
  background: #111;
  outline: none;
  z-index: 100;
}

.dropdown-arrow {
  margin-left: 0.5rem;
  cursor: pointer;
  color: white;
  opacity: 0.8;
}

.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #f8f7fa;
  text-decoration: none;
  padding: 8.5px 8px;
}

.asset-item:hover {
  background: #141414;
  color: white;
  border-radius: 4px;
}

.asset-item p {
  margin: 0;
  margin-left: 5px;
}