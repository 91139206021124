.TimeTab.block {
  display: grid;
  grid-auto-flow: column;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
}

.TimeTab-option-icon {
  margin-right: 0.3rem;
  opacity: 0.7;
}

.TimeTab-option {
  position: relative;
  display: flex !important;
  flex-direction: row-reverse !important;
  width: 75px;
  justify-content: center;
  align-items: center;
}

.TimeTab.block .TimeTab-option {
  text-align: center;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  border-radius: 0px;
  transition: all 0.3s ease;
}

.TimeTab-option.muted:hover,
.TimeTab-option.muted {
  opacity: 1 !important;
  color: var(--text-secondrary);
}

.TimeTab-option.new-item:before {
  content: "NEW";
  position: absolute;
  top: 50%;
  left: calc(100% + 6px);
  transform: translateY(calc(-50% - 2px));
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 8px;
  width: 36px;
  height: 16px;
  font-weight: 900;
  background-color: var(--success-active);
  border-radius: 13px;
  transition: all 0.3s ease;
}

.TimeTab.block .TimeTab-option:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.TimeTab.block .TimeTab-option:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.TimeTab.block .TimeTab-option:hover {
  color: var(--action-hover);
}

.TimeTab.block .TimeTab-option.active {
  opacity: 1;
  pointer-events: none;
  color: var(--action-active);
}

.TimeTab.block .TimeTab-option.active .TimeTab-option-icon {
  opacity: 1;
}

.TimeTab.inline .TimeTab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
}

.TimeTab.inline .TimeTab-option:hover {
  opacity: 0.8;
}

.TimeTab.inline .TimeTab-option.active {
  opacity: 1;
  pointer-events: none;
  color: var(--text-primary);
}