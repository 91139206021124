.chart-token-selector {
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  white-space: nowrap;
}

.chart-token-selector--current {
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 10px;
  color: white;
  font-family: Inter, sans-serif;
  white-space: nowrap;
}

.TokenSelector-box {
  font-family: Inter, sans-serif;
}

.chart-token-menu {
  z-index: 2;
}

.chart-token-menu-items.menu-items {
  width: 158px;
  top: 60px;
  right: unset;
  left: 0;
}