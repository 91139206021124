html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Inter";
  letter-spacing: 0.5px;
  font-size: 15.5px;
  background-color: var(--bg);
}

* {
  box-sizing: border-box;
  font-family: "Inter";
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--cell-highlight);
  border: 1px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

:root {
  --light-green: #00e55c;
  --dark-accent: #f8f7fa;
  --dark-hover: #808aff14;
  --dark-active: #000000;
  --border-radius-sm: 4px;
  --success-active: #23c453;
  --text-gray-400: #9ca3af;
  --text-gray-icon: #848E9C;
  --short-active: #dd463a;
  --long-active: #28e06f;
  --card-bg: #1c2024;
  --light-bg: #1c2024;
  --darkest-green: #001100;
  --tooltip-bg: rgb(35, 40, 45);
  --border: #252930;
  --bg: #161a1e;
  --bg-tab: #161a1e;
  --outlined-primary: rgba(0, 255, 183, 0.15);
  --outlined-primary-hover: rgba(0, 255, 179, 0.25);
  --background-secondary: #2a2d35;
  --background-navigation: #000;
  --background-gradient: #000;
  --bg-primary: #41E6AF;
  --border-navigation: #22E4C0;
  --tw-gradient-to: #3B82F6;
  --tw-gradient-from: #46B2A7;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --action-active: #23D1B0;
  --action-inactive: #112720;
  --action-hover: #22E4C0;
  --action-stroke: #02EDC3;
  --action-shadow: 0 0 4px 6px rgba(0, 130, 85, 0.1);
  --action-gradient: linear-gradient(267.64deg, #00301c80 -84.72%, rgba(0, 48, 35, 0) 144.55%);

  --cell-gradient: linear-gradient(180deg, rgba(1, 67, 49, 0.2) 0%, rgba(1, 67, 40, 0) 65.01%);
  --cell-hover: #252930;
  --cell-highlight: #23D1B0;
  --cell-unavailable: rgba(49, 136, 120, 0.05);
  --cell-unavailable-stroke: rgba(49, 136, 108, 0.2);

  --tooltip-fill: #252930;
  --tooltip-shadow: 0px 0px 7px rgba(39, 89, 77, 0.53);

  --text-primary: #fff;
  --text-secondary: #eee;
  /* Same as secondary but unsure what it should be so keeping seperate */
  --text-hover: #fff;
  --theme: rgb(39, 57, 84);
  --text-navigation: #fff;
  --danger: #191203;
  --danger-active: #FA3C58;
  --bg-stone: #1d362c;
  --card-hover: rgb(16 19 22);
  --backround-primary: var(--card-bg);
  --ds-surface-overlay: var(--card-bg) !important;
  --ds-background-neutral-subtle-hovered: var(--card-bg) !important;
  --bg-color: rgb(20, 20, 20);
  --card-color: #1E2834;

}

.light-theme {
  --bg-stone: var(--card-bg);
  --border: #ffffff36;
  --card-bg: #ffffff07;
  --text-gray-400: #fff;
  --text-gray-icon: #fff;
  --background-secondary: rgba(255, 255, 255, 0.028);
  --card-hover: #ffffff0c;
  --theme: #23D1B0;
  --background-gradient: #101925;
  --cell-highlight: rgb(39, 57, 84);
  --backround-primary: var(--card-bg);
  --light-bg: transparent;
  --tooltip-bg: rgb(35, 40, 45);
  --bg-tab: transparent;
}

a {
  color: white;
}

.Card-title {
  font-size: 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 1.3rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  padding: 0.8rem;
  font-family: "Inter";
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bg-theme {
  background-color: var(--bg);
}

button {
  outline: none;
  cursor: pointer;
  font-family: "Inter";
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.3rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  opacity: 0.7;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.margin-bottom {
  margin-bottom: 0.3rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.2rem;
}

.inline-block {
  display: inline-block;
}

.label,
.label a {
  color: var(--text-secondary);
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1rem;
}

a.plain {
  text-decoration: none;
}

.checkbox:checked:before {
  background-color: green;
}

.default-btn {
  background: var(--action-active);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff !important;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  appearance: none;
  transition: all 0.3s ease;
}

.default-btn:hover {
  background: var(--border);
}

/* .default-btn:focus {
  background: #515ed8;
  border: 1px solid #3D4FFE;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
} */

.default-btn:active {
  background: var(--cell-highlight);
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  overflow: hidden;
}

.default-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
}

.default-btn:disabled {
  background: var(--action-inactive) 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

.default-container {
  max-width: 1264px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}

.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: 1rem;
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.1rem;
  margin-top: -0.2rem;
  margin-right: 0.35rem;
  margin-left: -0.3rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 4rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
}

.page-layout .footer {
  padding: 1rem 0;
  margin-bottom: 0;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.default-cursor.App-cta {
  cursor: default;
}