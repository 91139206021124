.App {
  background-image: var(--bg) !important;
}

.StakeV2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.StakeV2 .Page-title-section {
  position: relative;
  z-index: 2;
  padding-left: 0;
  min-height: 75px;
  margin-bottom: 1rem;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.3rem;
}

.StakeV2-address-input {
  padding: 1rem 2.2rem;
  padding-bottom: 0;
}

.StakeV2-buy-kmx-modal .Modal-content,
.StakeModal .Modal-content {
  max-width: 30rem;
  min-width: 424px;
}

.StakeV2-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
}

.StakeV2-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.2rem;
  border-radius: 2px;
  width: 1rem;
  height: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.StakeV2-boost-icon {
  font-size: 0.7rem;
  z-index: 2;
}

.StakeV2-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

.Spread-capture-swap>.TokenSelector-box-symbol {
  margin-left: 0.5rem;
  display: none;
}

.Klp-price-chart.exchange-chart.tv {
  height: 20rem;
}

.Klp-price-chart.exchange-chart.tv .exchange-chart-bottom-stats {
  margin-left: 0;
}

.Klp-price-chart.exchange-chart.tv>.exchange-chart-bottom {
  top: 0;
  border-radius: 0px;
  border: none;
  left: 2px;
  bottom: 0;
  background: url(../../img/background_mesh.png);
  background-size: cover;
}

@media (max-width: 424px) {
  .StakeModal .Modal-content {
    margin-right: 1rem;
    margin-left: 1rem;
    min-width: calc(100% - 2rem);
  }
}

@media (max-width: 900px) {
  .StakeV2-total-rewards-card {
    grid-row: 4;
  }
}