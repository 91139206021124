.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 0.9rem;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--backround-primary);
}

.Tab-option-icon {
  margin-right: 0.3rem;
  opacity: 0.7;
}

.Tab-option {
  position: relative;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  border-radius: 0px !important;
  border: 0;
  transition: all 0.3s ease;
}

.Tab-option.muted:hover,
.Tab-option.muted {
  opacity: 1 !important;
  color: var(--text-secondrary);
}

/* remove this on enabled */
.Exchange-swap-order-type-tabs .Tab-option:nth-child(2) {
  /* styles for the "Limit" element */
  pointer-events: none !important;
  opacity: 0.3 !important;
}

.Exchange-swap-order-type-tabs .Tab-option:nth-child(3) {
  /* styles for the "Trigger" element */
  pointer-events: none !important;
  opacity: 0.3 !important;
}

/* 
.Tab-option.new-item:before {
  content: "NEW";
  position: absolute;
  top: 50%;
  left: calc(100% + 6px);
  transform: translateY(calc(-50% - 2px));
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 8px;
  width: 36px;
  height: 16px;
  font-weight: 900;
  background-color: var(--success-active);
  border-radius: 13px;
  transition: all 0.3s ease;
} */

.Tab.block .Tab-option:first-child {
  border-top-left-radius: 3px;
}

.Tab.block .Tab-option:last-child {
  border-top-right-radius: 3px;
}

.Tab.block .Tab-option:hover {
  color: var(--action-hover);
}

.Tab.block .Tab-option {
  border-top: 2px solid transparent;
  background-color: var(--bg-tab);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  border-top: 2px solid rgb(35 209 176);
  border-bottom: none !important;
  color: var(--action-active);
  cursor: pointer;
  background-color: var(--card-bg) !important;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  color: var(--text-primary);
}