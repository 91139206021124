.Modal,
.Modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.Modal {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left
}

.Modal-backdrop {
  z-index: 10;
  background: rgba(0, 0, 0, .9)
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  border: 1px solid var(--border);
  color: #eaecef;
  background-color: var(--card-bg);
  box-shadow: rgb(0 0 0/16%)0 16px 32px, rgb(0 0 0/16%)0 8px 16px, rgb(94 102 115/90%)0 0 1px inset;
  border-radius: 6px
}


.Modal-title-bar {
  position: absolute;
  top: -80px;
  display: flex;
  align-items: center;
  margin: 1.25rem;
  flex-direction: row;
  width: 100%;
  left: 0px;
  margin-left: 0 !important;
}

.dark\:bg-dark-bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(35 46 66/var(--tw-bg-opacity))
}

@media (min-width:768px) {
  .md\:\!max-w-\[700px\] {
    max-width: 700px !important
  }
}

.font-extrabold {
  font-weight: 800
}

.dark\:bg-dark-gray-light {
  background-color: rgb(46 60 86)
}

.dark\:text-dark-typo-primary {
  color: rgb(226 235 251)
}

.normal-case {
  text-transform: none
}

.font-semibold {
  font-weight: 600
}

.gap-x-2 {
  -webkit-column-gap: .5rem;
  column-gap: .5rem
}

.h-10 {
  height: 2.5rem
}

.pt-0 {
  padding-top: 0
}

.rounded-full {
  border-radius: 9999px
}

.inset-0 {
  inset: 0
}

.p-\[3px\] {
  padding: 3px
}


.nt-btn {
  border-radius: 4px;
  margin: 0.5em 0px;
  border: 1px solid rgb(43, 47, 54);
  text-align: center;
  color: rgb(230, 232, 234);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.3s ease 0s;
  background: rgb(43, 47, 54);
  padding: 16px;
}

.nt-btn:hover {
  border-color: var(--bg-primary);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .3s
}

.p-0\.5 {
  padding: .125rem
}

.border-cyan {
  --tw-border-opacity: 1;
  border-color: rgb(42 198 219/var(--tw-border-opacity))
}

.border-solid {
  border-style: solid
}

.border {
  border-width: 1px
}

.rounded-xl {
  border-radius: .75rem
}

.z-20 {
  z-index: 20
}

.right-2\.5 {
  right: .625rem
}

.top-10 {
  top: 2.5rem
}

.right-3 {
  right: .75rem
}

.absolute {
  position: absolute
}

.duration-200 {
  transition-duration: .2s
}

.network.active {
  background-image: linear-gradient(90deg, var(--bg-primary) 0%, hwb(192 0% 0%) 100%)
}

.rounded-3xl {
  border-radius: 1.5rem
}

.self-center {
  align-self: center
}

.justify-center {
  justify-content: center
}

.items-center {
  align-items: center
}

.cursor-not-allowed {
  cursor: not-allowed
}

.p-0 {
  padding: 0
}

.w-2\/3 {
  width: 66.666667%
}

.h-12 {
  height: 3rem
}

.justify-end {
  justify-content: flex-end
}

.mb-8 {
  margin-bottom: 2rem
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem rem
}

.pl-6 {
  padding-left: 1.5rem
}

@media (min-width:768px) {
  .md\:pr-10 {
    padding-right: 2.5rem
  }
}

.dark\:bg-dark-bg-primary {
  background-color: rgb(18 21 38)
}

.flex {
  display: flex
}

@media (min-width:768px) {
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .md\:pt-0 {
    padding-top: 0
  }
}

.m-1 {
  margin: .25rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.text-body {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: .03em;
  font-weight: 500
}

.text-h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.divider {
  border-bottom: 1px solid var(--border);
  margin-bottom: 1rem
}

.Modal.non-scrollable .Modal-content {
  overflow: visible
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem
}

.Modal-body {
  margin: 1rem
}

.Modal-body::-webkit-scrollbar {
  width: 6px
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 100rem
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 100rem
}

.Modal-title {
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: .025em;
}

.Modal-close-button {
  text-align: right
}

.Modal-close-icon {
  opacity: .6;
  cursor: pointer;
  text-align: right;
  display: inline-block
}

.Modal-close-icon:hover {
  opacity: .9
}

.Modal-note {
  margin-bottom: 1rem;
  margin-top: .5rem
}