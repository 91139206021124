.Selector.disabled {
  pointer-events: none;
}

.Selector.disabled .Selector-caret {
  display: none;
}

.Selector .Modal-backdrop {
  height: 100vh;
  backdrop-filter: blur(4px);
  background: rgba(0, 10, 0, 0.8);
}

.Selector-box {
  width: 72px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid var(--border-navigation);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 7px 8px;
}

.Selector-caret {
  vertical-align: middle;
  display: inline-block;
  font-size: 1.5rem;
  margin-top: -0.2rem;
}

.Selector-option {
  cursor: pointer;
  background: var(--outlined-primary);
  border: 1px solid var(--action-hover);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.Selector-option:hover {
  background: var(--action-hover);
}

.Selector-option+.Selector-option {
  margin-top: 0.5rem;
}

.Selector-option .Selector-option_icon {
  width: 32px;
  margin-right: 1rem;
}

.Selector-option .Selector-option_label {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.29px;
  color: #ffffff;
}

.Selector-option.Arbitrum:hover {
  background: #808aff14 !important;
  border: 1px solid #4275a8;
}

.Selector-option.Avalanche:hover {
  background: #1f213e !important;
  border: 1px solid #e84142;
}

.Selector-option .selected-icon {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 1rem;
}

.Selector-option.Arbitrum .selected-icon {
  background-color: #4275a8;
}

.Selector-option.Avalanche .selected-icon {
  background-color: #e84142;
}

.selected-icon {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 1rem;
}

.Selector .Modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Selector .Modal-title {
  color: white;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  opacity: 1;
}

.Selector .Modal-content {
  width: 100%;
  max-width: 19.1rem;
  margin: auto;
  border: 1px solid var(--action-stroke);
  border-radius: 4px;
}

.react-select__control {
  transition: background-color 0.3s ease;
}

.react-select__control:hover {
  background-color: var(--action-active);
}

.react-select__option {
  border: 1px solid transparent;
  border-radius: 4px;
}

.react-select__option:hover {
  border: 1px solid var(--action-active);
  color: #eee;
}

.react-select__option:active {
  background: var(--action-active) !important;
}

.react-select__option.avalanche:active {
  background: var(--action-active) !important;
}

.react-select__option.avalanche:hover {
  border: 1px solid #e84142;
  border-radius: 4px;
  color: #eee;
}

.react-select__indicator {
  border-radius: 4px;
}

.network-select {
  background: var(--action-stroke);
  border-radius: 4px;
}

.network-select:hover {
  background: var(--action-stroke);
}

.network-select:active {
  background: var(--action-stroke);
}

.app-header-user-settings:hover {
  background: var(--action-stroke);
}

@media (max-width: 900px) {
  .Selector .network-label {
    display: none;
  }
}